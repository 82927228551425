@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
@import 'maplibre-gl/dist/maplibre-gl.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

/* Map Container Styles */
.maplibregl-map {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.maplibregl-canvas-container {
  height: 100%;
  width: 100%;
}

.maplibregl-canvas {
  outline: none;
}

/* Custom Popup Styles */
.maplibregl-popup {
  z-index: 40 !important;
}

.maplibregl-popup-content {
  padding: 0 !important;
  background: none !important;
  box-shadow: none !important;
}

.maplibregl-popup-tip {
  display: none !important;
}

/* Animation Keyframes */
@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes enter-from-right {
  from {
    opacity: 0;
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes enter-from-left {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes exit-to-right {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(10px);
  }
}

@keyframes exit-to-left {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-10px);
  }
}

@keyframes scale-in {
  from {
    opacity: 0;
    transform: rotateX(-10deg) scale(0.9);
  }
  to {
    opacity: 1;
    transform: rotateX(0deg) scale(1);
  }
}

@keyframes scale-out {
  from {
    opacity: 1;
    transform: rotateX(0deg) scale(1);
  }
  to {
    opacity: 0;
    transform: rotateX(-10deg) scale(0.95);
  }
}

@keyframes float {
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}
